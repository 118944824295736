.logo {
  height: 55px;
  width: auto;
}

.logo-text {
  font-family: 'Space Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
