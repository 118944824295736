$white: white;
$black: black;
$transparent: transparent;
$grey: grey;
$background: #f6f6f6;

$mint: #a7e97f;
$solid-blue: #202b30;
$light-grey: #ededed;

$border-color: #dedede;

$primary: #54b75e;
$secondary: #babdce;
$warning: #f69e7b;
$danger: #f77272;
$success: #54b75e;
$light: #f9fbff;
