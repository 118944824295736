@import '../../styles-variables.scss';

.bg {
  background-image: url('../../assets/auth-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.img {
  width: 150px;
  height: auto;
}
