@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');

@import './theme.scss';
@import './styles-variables.scss';

html,
body,
#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: $background;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-style: normal;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}

@import '~bootstrap/scss/bootstrap';
