@import '../../styles-variables.scss';

.input {
  input {
    border: none;
  }
}

.submit-btn {
  background: none;
  border: none;
}
