@import './styles-variables.scss';

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  warning: $warning,
  danger: $danger,
  success: $success,
  light: $light,
  grey: $grey,
);

.card {
  border: 1px solid $border-color !important;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.06) !important;
}

.btn:hover,
.btn-primary {
  color: $white !important;
}

.modal-title {
  font-size: 1.2rem !important;
}

.nav-tabs {
  margin-bottom: 1rem !important;
}

a {
  text-decoration: none !important;
}

.grow {
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

hr {
  color: $secondary;
}

.tooltip-inner {
  text-align: start !important;
}

.form-checkbox {
  .form-check-input {
    border: 1px solid $grey !important;
    width: 1.1em !important;
    height: 1.1em !important;
  }
}

@import '~bootstrap/scss/bootstrap';
