@import '../styles-variables.scss';

.navbar {
  background-color: $white;
  border: 1px solid $border-color !important;
}

.logo {
  height: 35px;
  width: auto;
}

.logo-text {
  color: $solid-blue;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 35px;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
