@import '../../styles-variables.scss';

.container {
  background-color: $solid-blue;
  background: linear-gradient(56deg, rgba(70, 73, 74, 1) 0%, rgba(32, 43, 48, 1) 53%, rgba(2, 15, 22, 1) 100%);
  border-top: 7px solid $mint;
  color: $white;
  padding: 6rem 0;
}

.logo {
  height: 55px;
  width: auto;
}

.logo-text {
  font-family: 'Space Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.text {
  font-size: 45px;
}
